import { useEffect } from "react";
import { useQuery } from "react-query";

declare var DisplayTag: (category: string) => {};

interface Props {
  website: number;
  ciblage: number;
  insuranceKind: number;
}

interface Script {
  Position: number;
  Contenue: string;
}

export const Tags: React.FC<Props> = ({ website, ciblage, insuranceKind }) => {
  const parameters: Object = {
    Website: website,
    Ciblage: ciblage,
    InsuranceKind: insuranceKind,
    Url: window.location.href,
    UsId: localStorage.getItem("UserSheetId"),
    onlyCiblageEnum: true
  };

  // Call API
  const { data, error } = useQuery(
    "tags",
    async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Tag/LaunchAndGetTag`, {
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        credentials: "include",
        method: "POST",
        body: JSON.stringify(parameters),
      });
      return response.json();
    },
    { refetchOnWindowFocus: false }
  );

  //Indique si le fragment a déjà été rajouté au DOM
  function IsNotAlreadyInDom(fragment: DocumentFragment, target: Node):boolean  {
    var findComments = function(el: Node) {
      var arr: ChildNode[] = [];
      el.childNodes.forEach((node) => {
          if(node.nodeType === 8)
              arr.push(node);
          else if(node.nodeName === "TEXTAREA"){
            let textAreaFragment = document.createRange().createContextualFragment(node.textContent as string);
            textAreaFragment.childNodes.forEach((el) => {
              if(el.nodeType === 8) 
                arr.push(el);
            });
          }
      });
      return arr;
    };

    var findScript = function(fragment: DocumentFragment, el: Node){
      let isScriptInDom = false;
      for(var i = 0; i < document.scripts.length; i++){
        let script = document.scripts[i];
        isScriptInDom = isScriptInDom || (fragment.childElementCount === 1 && (fragment.firstChild as HTMLElement).innerHTML === script.innerHTML && script.parentNode?.nodeName === el.nodeName);
      }
      return isScriptInDom;
    }

    let comment: null|string = "";
    if(fragment.childElementCount === 1 && fragment.firstChild?.nodeName === "TEXTAREA"){
      const childFragment = document.createRange().createContextualFragment(fragment.firstChild.textContent as string);
      childFragment.childNodes.forEach((el) => {
        if(el.nodeType === 8 && !comment) comment = el.textContent;
      });
    }else{
      fragment.childNodes.forEach((el) => {
        if(el.nodeType === 8 && !comment) comment = el.textContent;
      });
    }

    return comment !== "" ? findComments(target).filter((com) => com.textContent === comment).length === 0 : !findScript(fragment, target);
  }

  // Get cookie
  function getCookies(){
    let cookies: {[name: string]: string} = {};
    document.cookie.split(";").forEach((el) => {
      cookies[el.split("=")[0].trim()] = el.split("=")[1];
    })
    return cookies;
  }

  // Ajoute les scripts qui ne sont pas renvoyés par l'API
  function addScript(src: string, type: string = "", crossOrigin: string = "", onLoad = () => {}) {
    if(document.querySelector("script[src='" + src + "']")){
      onLoad();
      let cookies = getCookies();
      if(cookies["Consent_CookieStatistiques"])
        DisplayTag("CookieStatistiques");
      if(cookies["Consent_CookieMarketing"])
        DisplayTag("CookieMarketing");
      if(cookies["Consent_CookiePreferences"])
        DisplayTag("CookiePreferences");
    }else{
      const TagGeneral = document.createElement("script");
      if (type !== "") {
        TagGeneral.type = type;
      }
      if (crossOrigin !== "") {
        TagGeneral.crossOrigin = crossOrigin;
      }
      TagGeneral.src = src;
      TagGeneral.onload = onLoad;
      document.body.appendChild(TagGeneral);
    }
  }

  // Ajoute les scripts renvoyés par l'api à la fin du document
  useEffect(() => {
    // Import Tag General
      data &&
      addScript(`${process.env.REACT_APP_FORMS_URL}/Scripts/Build/TagGeneral.js`, "module", "", () => {
        data &&
          data.AllTags &&
          data.AllTags.forEach((script: Script) => {
            const divFragment = document.createRange().createContextualFragment(`${script.Contenue}`);
            if (script.Position === 1) IsNotAlreadyInDom(divFragment, document.body) && document.body.append(divFragment);
            else IsNotAlreadyInDom(divFragment, document.head) && document.head.append(divFragment);
          });
      });
    data && data.UrlCommandersAct && addScript(data.UrlCommandersAct);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return <span>Erreur lors de l'appel API des tags</span>;
  }

  return <div data-cookiedomain={`${process.env.REACT_APP_DOMAIN_COOKIE}`}></div>;
};
